body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead{

      padding-bottom: 2em;
  }
  
  thead th {
    text-align: center;
    padding:0px;
    padding-left: 0.5em;
    color:black;
    padding-right:0.5em;
    border-bottom: 0.1rem solid #e1e1e1;
  }

  thead th:first-of-type{
    text-align: left;
    padding:0px;
    padding-left: 0.5em;

    padding-right:0.5em;

    border-bottom: 0.1rem solid #e1e1e1;
  }
  
  thead button {
    border: 0;
    border-radius: none;
    font-family: inherit;
    padding:0px;
    font-size:1em;
    font-weight: 700;
    margin-bottom: 1px;
    background-color: white;
    color:black;
    word-break: break-all;
    white-space: nowrap;
    text-align: left;
    vertical-align: center;
    text-transform: none;
  }

  thead button:hover {
   
    background-color: white;
    color:black;
  }

  thead button:focus {
   
    background-color: white;
    color:black;
  }
  
  thead button.ascending::after {
    content: '▲';
    display: inline-block;
    margin-left: 0.5em;
    background-color: white;
    color: black;
  }
  
  thead button.descending::after {
    content: '▼';
    display: inline-block;
    margin-left: 0.5em;
    background-color: white;
    color: black;
  }
  
  tbody td {
    padding: 0px;
    padding-top:  0.5em;
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right:1em;
    border-bottom: 0.1rem solid #e1e1e1;
    word-break: break-all;
    white-space: nowrap;
    text-align: left;
    vertical-align: center;
  }

  tbody tr td {
    padding: 0px;
    padding-top:  0.5em;
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right:1em;
    border-bottom: 0.1rem solid #e1e1e1;
    word-break: break-all;
    white-space: nowrap;
    text-align: left;
    vertical-align: center;
  }
 

  tbody td:first-of-type {
    padding: 0px;
    padding-top:  0.5em;
    padding-bottom: 1em;
    padding-right:0.5em;
    padding-left: 0.5em; 
    word-break: break-all;
    white-space: nowrap;
    text-align: left;
    vertical-align: center;
    border-bottom: 0.1rem solid #e1e1e1;;
  }


  
  tbody tr:hover {
    background-color: white;
  }
  
  .buttonsContainer {
    display: flex;
    justify-content: space-evenly;
    max-width: 80%;
    margin: 1rem auto;
  }
  
  .buttonsContainer button {
    all: unset;
    padding: 15px 32px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    background-color: white;
    color: black;
    text-decoration: none;
    border: 2px solid maroon;
    transition-duration: 0.4s;
    border-radius: 5px;
   }
  .buttonsContainer button:hover {
    background-color: lightgray;
    color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24),
      0 5px 5px 0 rgba(0, 0, 0, 0.19);
  }
  
  .buttonsContainer .disabled {
    background-color: none !important;
    border: 1px solid #999999;
    background-color: #cccccc !important;
    color: #666666;
    cursor: default;
  }
  
  .buttonsContainer .disabled:hover {
    outline: none;
    background-color: #cccccc;
    color: #666666;
    box-shadow: none;
  }
  
  .loading {
    max-width: fit-content;
    margin: 10rem auto;
    top:20px; 
    justify-content: center;
    vertical-align: top;
    font-size: 600px;
    font-weight: 300;
  }
  
  .loading p {
    font-size: 3500px;
    font-weight: 300;
    color:black;
  }