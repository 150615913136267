.loading {
    max-width: fit-content;
    margin: 10rem auto;
    top:20px; 
    justify-content: center;
    vertical-align: top;
  }
  
  .loading p {
    /* margin-top: 1rem; */
    font-size: 30px;
    font-weight: 300;
    color:black;
  }